import React, { useEffect } from "react";
import Nav from "../components/nav";
import "../styles/web-service.css";
import Contact from "../components/contact";
import Footer from "../components/Footer";
import useScrollAnimation from "../custom-hook/scrolleranimation";
import BackToTopButton from "../components/BackToTopButton";
import { Helmet } from 'react-helmet-async';

export default function Web() {
  const containerRef = useScrollAnimation();

  const ogData = {
    type: "website",
    title: "Web Development Services | Micro IT Industry",
    description:
      "Boost your online presence with Micro IT Industry's expert web development services. We create dynamic, responsive, and SEO-optimized websites tailored to your needs. Contact us today!",
    url: "https://www.microitindustry.com/web-development-services",
    image:
      "/public/assets/services/web-development.png",
    siteName: "Micro IT Industry",
  };

  useEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
     <Helmet>
        <title>{ogData.title}</title>

        {/* Open Graph Meta Tags */}
        <meta property="og:type" content={ogData.type} />
        <meta property="og:title" content={ogData.title} />
        <meta property="og:description" content={ogData.description} />
        <meta property="og:url" content={ogData.url} />
        <meta property="og:image" content={ogData.image} />
        <meta property="og:site_name" content={ogData.siteName} />
        {/* You can add more Open Graph tags as needed */}

        {/* Other standard meta tags */}
        <meta name="description" content={ogData.description} />
      </Helmet>
      <Nav />
      <div>
        <div className="container">
          <div
            className="reviews_heading"
            ref={containerRef}
            style={{ marginTop: "25px" }}
          >
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 d-flex flex-column justify-content-start mb-3 mb-lg-0 text-container">
                <div className="parallelogram-background">
                  <h1 className="web-h1-flex">
                    <span className="web-s1-h1">
                      Web Development{" "}
                      <span className="web-s2-h1">Services</span>
                    </span>
                  </h1>
                </div>
                <p className="text" style={{ fontSize: "18px" }}>
                  At <b>Micro IT Industry</b>, we offer comprehensive <b>web development
                    services</b> tailored to meet the unique needs of our clients. Our
                  team of skilled developers specializes in creating dynamic,
                  user-friendly websites that are not only visually appealing
                  but also optimized for performance and security. We utilize
                  the latest technologies and frameworks to ensure your website
                  is fast, responsive, and compatible across all devices. From
                  <b> e-commerce platforms</b> to <b>content management systems</b>, we provide
                  end-to-end solutions that include <b>front-end</b> and <b>back-end development</b>
                  , <b>API integrations</b>, and <b>custom software
                    development</b>. Our commitment to quality and attention to detail
                  ensures that your digital presence stands out in a competitive
                  market. Additionally, we offer ongoing maintenance and support
                  services to keep your website running smoothly and up-to-date.
                  Partner with <b>Micro IT Industry</b> to elevate your online presence
                  and achieve your business goals with our expert web
                  development services.
                </p>
                <div>
                  <a
                    className="btn btn-primary bg-gradient1 color-2 font-weight-bold"
                    style={{
                      cursor: "pointer",
                      fontSize: "1.2rem",
                    }}
                    href="https://calendly.com/microitindustry/meet-with-us"
                    target="_blank"
                  >
                    Schedule a Call
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 d-flex justify-content-center image-container">
                <img
                  className="web-main-img"
                  src="/assets/services/web-development.png"
                  alt="Web App Developement Services at Micro IT Industry"
                />
              </div>
            </div>
          </div>
          <div className="container" style={{ marginTop: "20px" }}>
            <div className="line"></div>
            <div className="semi-line"></div>
            <div className="row" ref={containerRef}></div>
          </div>
        </div>
      </div>
      <div style={{ height: "60px" }}></div>
      <Contact />
      <Footer />
      <BackToTopButton />
    </>
  );
}
