import React from "react";
import "../styles/reviews.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import useScrollAnimation from "../custom-hook/scrolleranimation";

export default function Reviews() {
  const containerRef = useScrollAnimation();

  return (
    <>
      <div className="reviews">
        <div className="line"></div>
        <div className="semi-line"></div>
        <div
          className="container"
          style={{ marginTop: "1rem" }}
          ref={containerRef}
        >
          <div className="reviews_heading">
            <div className="borderlinemid"></div>
            <h1 className="mt-3 d-flex justify-content-center"> Why Customer Love</h1>
              <h1 className="d-flex justify-content-center" style={{ fontWeight: "700" }}>
              Working With Us
              </h1>
          </div>

          <Carousel
            showArrows={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            interval={3100}
          >
            <div>
              <img
                src={process.env.PUBLIC_URL + "/assets/clients/tawfiq.png"}
                alt="Tawfiq the client of Micro IT Industry"
              />
              <div
                className="myCarousel"
                style={{ backgroundColor: "#0F6C3F" }}
              >
                <h3>Tawfiq Aljohani</h3>
                <h4>🇸🇦 Saudi Arabia</h4>
                <p
                  className="reviewP"
                  dir="rtl"
                  lang="ar"
                  style={{ color: "#e0e0e0" }}
                >
                  إذا كنت تتطلع إلى العمل مع كيان يمكنه مساعدتك في تحقيق
                  إمكاناتك الكاملة، فهذا هو المكان المناسب. لقد لعبوا دورًا
                  رئيسيًا في مساعدتي لتطوير منصة blockchain في فترة زمنية قصيرة
                  وبجودة عالية. وإنني أتطلع إلى العمل معهم في مشاريعي الأخرى.
                </p>

                <p className="reviewP">
                  If you are looking to work with an entity that can help you
                  realize your full potential, this is the place. They played a
                  major role in helping me to develop the blockchain platform in
                  a short period of time and with great quality. I look forward
                  to working with them on my other projects.
                </p>
              </div>
            </div>

            <div>
              <img
                src={process.env.PUBLIC_URL + "/assets/clients/cejay.webp"}
                alt="Cejay the client of Micro IT Industry"
              />
              <div
                className="myCarousel"
                style={{ backgroundColor: "#012479" }}
              >
                <h3>Cejay Riches</h3>
                <h4>🇺🇸 United States</h4>
                <p>
                  This guys goes beyond and above. he's hands honds and truly
                  has patience unlike no other truly grateful for this guy.
                </p>
              </div>
            </div>

            <div>
              <img src={process.env.PUBLIC_URL + "/assets/clients/salwa.png"} alt="Salwa the client of Micro IT Industry"/>
              <div
                className="myCarousel"
                style={{ backgroundColor: "#0F6C3F" }}
              >
                <h3>Salwa</h3>
                <h4>🇸🇦 Saudi Arabia</h4>
                <p
                  className="reviewP"
                  dir="rtl"
                  lang="ar"
                  style={{ color: "#e0e0e0" }}
                >
                  لقد سبق لي التعامل مع المهندس محمد في تطوير موقع إلكتروني،
                  وكان تعاونه واستجابته ممتازة. تم إنجاز الموقع في وقت قصير
                  بأفضل طريقة ممكنة.
                </p>
                <p>
                  I have previously dealt with Engineer Mohammed in developing a
                  website, and his cooperation and response were excellent. The
                  site was completed in a short time and in the best possible
                  way.
                </p>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
}
