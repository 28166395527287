import React from "react";
import "../styles/slide.css";
import useScrollAnimation from "../custom-hook/scrolleranimation";

export default function Slideline() {
  const containerRef = useScrollAnimation();
  return (
    <>
      <div className="slideA" ref={containerRef}>
      <div className='line'></div>
      <div className='semi-line'></div>

        <div className=" mt-3 container">
          <div className="reviews_heading">
            <div className="borderlinemid"></div>
            <h1 className="mt-3 d-flex justify-content-center">
              How development
            </h1>
            <h1
              className="d-flex justify-content-center"
              style={{ fontWeight: "700" }}
            >
              {" "}
              through Alcaline works
            </h1>
          </div>
        </div>
        <div className="container">
          {/* 3cards before the line  */}

          <div className="before-line">
            <div className="mt-3 row justify-content-center">
              <div class="card mt-2 service" style={{ maxWidth: "320px" }}>
                <div class="card-body">
                  <div className="d-flex">
                    <p
                      style={{
                        color: " #009EF5",
                        fontSize: "1.3rem",
                        fontWeight: "700",
                      }}
                    >
                      #1
                    </p>
                    <p
                      style={{
                        marginLeft: "4px",
                        fontSize: "1.3rem",
                        fontWeight: "600",
                      }}
                    >
                      Assemble the right team
                    </p>
                  </div>
                  <div class="">
                    <p style={{ color: "#718096" }}>
                      We handle all aspects of vetting and choosing the right
                      team that you don't have the time, expertise, or desire to
                      do.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card mt-2 service" style={{ maxWidth: "320px" }}>
                <div class="card-body">
                  <div className="d-flex">
                    <p
                      style={{
                        color: " #009EF5",
                        fontSize: "1.3rem",
                        fontWeight: "700",
                      }}
                    >
                      #2
                    </p>
                    <p
                      style={{
                        marginLeft: "4px",
                        fontSize: "1.3rem",
                        fontWeight: "600",
                      }}
                    >
                      Tech architecture
                    </p>
                  </div>
                  <div class="">
                    <p style={{ color: "#718096" }}>
                      We break monolithic apps into microservices. Decoupling
                      the code allows teams to move faster and more
                      independently
                    </p>
                  </div>
                </div>
              </div>
              <div class="card mt-2 service" style={{ maxWidth: "320px" }}>
                <div class="card-body">
                  <div className="d-flex">
                    <p
                      style={{
                        color: " #009EF5",
                        fontSize: "1.3rem",
                        fontWeight: "700",
                      }}
                    >
                      #3
                    </p>
                    <p
                      style={{
                        marginLeft: "4px",
                        fontSize: "1.3rem",
                        fontWeight: "600",
                      }}
                    >
                      Code reviews
                    </p>
                  </div>
                  <div class="">
                    <p style={{ color: "#718096" }}>
                      Code reviews before release help detect issues like memory
                      leaks, file leaks, performance signs, and general bad
                      smells
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* main centered line  */}
          {/* <div className="d-flex">
            <div className="line"></div>
            <div style={{ width: "30px", marginTop: "1rem" }}>
              <img
                src={process.env.PUBLIC_URL + "/assets/cup.png"}
                alt="working"
                className="img-fluid rounded"
              />
            </div>
          </div> */}

          {/* 3cards after the line  */}
          <div>
            <div className="mt-3 row justify-content-center">
              <div class="card mt-2 service" style={{ maxWidth: "320px" }}>
                <div class="card-body">
                  <div className="d-flex">
                    <p
                      style={{
                        color: " #009EF5",
                        fontSize: "1.3rem",
                        fontWeight: "700",
                      }}
                    >
                      #4
                    </p>
                    <p
                      style={{
                        marginLeft: "4px",
                        fontSize: "1.3rem",
                        fontWeight: "600",
                      }}
                    >
                      Sprint planning
                    </p>
                  </div>
                  <div class="">
                    <p style={{ color: "#718096" }}>
                      Sprint roadmap is a collective planning effort. Team
                      members collaborate to clarify items and ensure shared
                      understanding.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card mt-2 service" style={{ maxWidth: "320px" }}>
                <div class="card-body">
                  <div className="d-flex">
                    <p
                      style={{
                        color: " #009EF5",
                        fontSize: "1.3rem",
                        fontWeight: "700",
                      }}
                    >
                      #5
                    </p>
                    <p
                      style={{
                        marginLeft: "4px",
                        fontSize: "1.2rem",
                        fontWeight: "600",
                      }}
                    >
                      Standups & weekly demos
                    </p>
                  </div>
                  <div class="">
                    <p style={{ color: "#718096" }}>
                      Standups, weekly demos, and weekly reviews make sure
                      everyone is on the same page and can raise their concerns.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card mt-2 service" style={{ maxWidth: "320px" }}>
                <div class="card-body">
                  <div className="d-flex">
                    <p
                      style={{
                        color: " #009EF5",
                        fontSize: "1.3rem",
                        fontWeight: "700",
                      }}
                    >
                      #6
                    </p>
                    <p
                      style={{
                        marginLeft: "4px",
                        fontSize: "1.3rem",
                        fontWeight: "600",
                      }}
                    >
                      Iterative delivery
                    </p>
                  </div>
                  <div class="">
                    <p style={{ color: "#718096" }}>
                      We divide the implementation process into several
                      checkpoints rather than a single deadline.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
