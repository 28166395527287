import React from 'react'
import "../styles/first.css"
import useScrollAnimation from '../custom-hook/scrolleranimation';


export default function Firstsection() {

    const containerRef = useScrollAnimation();

    return (
        <>
            <div className='wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-lg-6" ref={containerRef}>
                            <div className="slide-in p-3 d-flex flex-column gap-7 items-start justify-start w-auto md:w-full content"
                                >
                                <h2 style={{ marginTop: "70px", color: "black", fontSize: "3rem" }}>
                                    <span style={{ fontWeight: "700", color: "#0067dc" }}>Micro </span>
                                    <span style={{ fontWeight: "700", color: "#019EF4" }}> IT </span>
                                    
                                    <span style={{ color: "grey", fontWeight: "700" }}>Industry</span> is global
                                    mission to <span style={{ color: "black", fontWeight: "700" }}> create cutting edge </span>
                                    <span style={{ color: "#3472FE", fontWeight: "700" }}>engineering solutions</span>
                                </h2>
                                <p style={{ color: "#4A5568", marginTop: "1.5rem", fontSize: "1.2rem" }}>We have build and manage a team of world-class engineers to bring your vision into real life.</p>
                                <div>
                                    <a
                                        className="btn btn-primary bg-gradient1 color-2 font-weight-bold"
                                        style={{
                                            marginTop: '30px',
                                            cursor: 'pointer',
                                            fontSize: '1.2rem',
                                        }}
                                        href='https://calendly.com/microitindustry/meet-with-us'
                                        target="_blank"
                                    >
                                        Schedule a Call
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6' style={{ marginBottom: "70px"}}>
                            <div className='image'>
                                <img src={process.env.PUBLIC_URL + '/assets/main.png'} alt="Micro IT Industry Software Company" className='img1' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}