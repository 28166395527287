import React, { useState, useEffect } from "react";
import Nav from "../components/nav";
import "../styles/blog-post.css";
import Contact from "../components/contact";
import Footer from "../components/Footer";
import BackToTopButton from "../components/BackToTopButton";
import { Helmet } from 'react-helmet-async';

export default function BlogPost() {
  const [isTocOpen, setIsTocOpen] = useState(false);

  const handleScroll = (event) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href").slice(1);
    const targetElement = document.getElementById(targetId);
    const offset = 100;
    const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
    setIsTocOpen(false); // Close the dropdown after scrolling
  };

  const ogData = {
    type: "website",
    title: "Top NON EVM Blockchain Plateforms",
    description:
      "Top NON EVM blockchain plateforms such as Bitcoin, Solana, Stellar, Hyperledger Fabric and Cardano that operating on virtual machine mechanisms and smart contract languages.",
    url: "https://www.microitindustry.com/blogs/top-non-evm-blockchain-plateforms",
    image: "/public/assets/blogs/top-non-evm-thumbnail.png",
    siteName: "Micro IT Industry",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const relatedPosts = [
  //   {
  //     title: "Introduction to AI in Web Development",
  //     image: "/assets/projects/builder.png",
  //     url: "#",
  //   },
  //   {
  //     title: "Top Web Performance Optimization Techniques",
  //     image: "/assets/projects/desktop-pos.png",
  //     url: "#",
  //   },
  //   {
  //     title: "Building Secure Web Applications",
  //     image: "/assets/projects/food-web-app.png",
  //     url: "#",
  //   },
  // ];

  return (
    <>

      <Helmet>
        <title>{ogData.title}</title>
        <meta property="og:type" content={ogData.type} />
        <meta property="og:title" content={ogData.title} />
        <meta property="og:description" content={ogData.description} />
        <meta property="og:url" content={ogData.url} />
        <meta property="og:image" content={ogData.image} />
        <meta property="og:site_name" content={ogData.siteName} />
        <meta name="description" content={ogData.description} />
      </Helmet>
      <Nav />
      <div className="blog-page container">
        <div className="blog-header">
          <div className="header-content">
            <h1 className="blog-title">
              Top Non EVM <span>Blockchain Plateforms</span>
            </h1>
            <div className="author-info">
              {/* <img src="/assets/zaryab.png" alt="John Doe" className="author-img" /> */}
              <a href="as" style={{ textDecoration: "none" }}><p className="author-name">Published: May 19, 2024</p></a>
            </div>
          </div>
          <img
            className="blog-main-img"
            src="/assets/blogs/top-non-evm.png"
            alt="The Future of Web Development"
          />
        </div>
        <div className="blog-body">
          <aside className={`toc ${isTocOpen ? "open" : ""}`}>
            <h3>Table of Contents</h3>
            <button className="toc-toggle" onClick={() => setIsTocOpen(!isTocOpen)}>
              {isTocOpen ? "Hide" : "Show"} TOC
            </button>
            <ul>
              <li><a href="#intro" onClick={handleScroll}>Introduction</a></li>
              <li><a href="#bitcoin" onClick={handleScroll}>Bitcoin</a></li>
              <li><a href="#solana" onClick={handleScroll}>Solana</a></li>
              <li><a href="#stellar" onClick={handleScroll}>Stellar</a></li>
              <li><a href="#hyperledger" onClick={handleScroll}>Hyperledger Fabric</a></li>
              <li><a href="#cardano" onClick={handleScroll}>Cardano</a></li>
              <li><a href="#conclusion" onClick={handleScroll}>Conclusion</a></li>
            </ul>
          </aside>
          <div className="blog-container">
            <div className="blog-content">
              <section id="intro">
                <p className="blog-text">
                  In a rapidly evolving blockchain ecosystem dominated by <b>EVM (Ethereum Virtual Machine)</b> mechanisms, a diverse array of <b>NON-EVM chains </b>
                  stands as a testament to innovation and versatility. These platforms, operating on distinct virtual machine mechanisms and smart contract languages,
                  offer unique solutions and capabilities beyond the confines of Ethereum and its counterparts. In this exploration, we delve into the top <b>NON-EVM</b> blockchain
                  platforms, each pioneering new paradigms in decentralization, scalability, and functionality.
                </p>
              </section>
              <section id="bitcoin">
                <h2>Bitcoin</h2>
                <p className="blog-text">
                  <b>Bitcoin</b> is the non-evm blockchain a decentralized cryptocurrency with a total supply of <b>21 million BTC</b>.
                  It works on the <b>proof of work</b> consensus mechanism and does not support the smart contract functionality.
                  <b> Bitcoin</b> is an open source that anyone can view, inspect, and contribute to Bitcoin. Bitcoin is designed in a way that halves the mining reward approximately every four years.
                </p>
              </section>
              <section id="solana">
                <h2>Solana</h2>
                <p className="blog-text">
                  <b>Solana</b> is the famous blockchain platform that uses the <b>proof of stake</b> consensus mechanism.
                  Its native token is <b>SOL</b> and not hard cap supply. <b>Solana</b> supports the smart contract functionality,
                  developers can write the smart contract in the <b>Rust</b> language using the <b>Anchor</b>  framework or native Rust.
                  It provides various tools and on-chain programs for NFT and Token creation such as Metaplex, Candy Machine,
                  Bubble Gum, and Solana Program Library.
                </p>
              </section>
              <section id="stellar">
                <h2>Stellar</h2>
                <p className="blog-text">
                  <b>Stellar</b> is the evolving non-evm blockchain that is used for payments & asset tokenization.
                  Its <b>Stellar Consensus Protocol (SCP)</b> is based on the <b>Federated Byzantine Agreement (FBA)</b>.
                  Its native token is <b>XLM</b> with a total supply of <b>50B XLMs</b>. Stellar provides the tools for developers
                  to create assets, and NFTs such as <b>Stellar Laboratory</b>, <b>okashi</b>. It supports the smart contract functionality,
                  developers can write smart using the <b>Soroban SDK</b> in the <b>Rust</b> language. For development, it has the Futurenet and Testnet networks,
                  and for production has Mainnet which requires the RPC URL from RPC providers.
                </p>
              </section>
              <section id="hyperledger">
                <h2>Hyperledger Fabric</h2>
                <p className="blog-text">
                  <b>Hyperledger Fabric</b> is an open-source enterprise-grade <b>permissioned blockchain</b> platform.
                  It was created by the <b>Linux Foundation</b> and is part of the Hyperledger project,
                  which aims to advance cross-industry blockchain technologies. Fabric allows organizations
                  to create private, permissioned blockchain networks tailored to their specific needs.
                </p>
              </section>
              <section id="cardano">
                <h2>Cardano</h2>
                <p className="blog-text">
                  <b>Cardano</b> is a blockchain platform focused on scalability, interoperability, and sustainability.
                  It aims to address the limitations of existing blockchain technologies by employing a layered
                  architecture and a research-driven approach to development. Cardano's native cryptocurrency is <b>ADA</b>. It uses a <b>proof-of-stake (PoS)</b> consensus mechanism called <b>Ouroboros</b>.
                </p>
              </section>
              <section id="conclusion">
                <h2>Conclusion</h2>
                <p className="blog-text">
                  <b>In conclusion</b>, the landscape of blockchain platforms extends far beyond the realm of <b>EVM-based</b> networks.
                  Highlighting some of the top <b>NON-EVM</b> platforms, we see a diverse array of functionalities and approaches.
                  <b> Bitcoin</b>, the pioneer of decentralized cryptocurrencies, operates on a proof-of-work consensus without
                  smart contract capabilities. <b>Solana</b>, with its proof-of-stake mechanism, empowers developers with smart contract
                  functionality through Rust-based programming. <b>Stellar</b>, utilizing Federated Byzantine Agreement, facilitates payments
                  and asset tokenization while supporting smart contracts via the Soroban SDK. <b>Hyperledger Fabric</b> caters to enterprise needs with
                  its permissioned blockchain solution, and <b>Cardano</b> pioneers scalability and sustainability through its innovative layered architecture.
                </p>
                <p className="blog-text">
                  These platforms showcase the evolving landscape of blockchain technology, offering unique solutions to various industry challenges beyond the confines of the Ethereum Virtual Machine.
                </p>
              </section>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="related-posts container">
        <h2>Related Posts</h2>
        <div className="card-container">
          {relatedPosts.map((post, index) => (
            <div key={index} className="card">
              <img src={post.image} alt={post.title} className="card-img" />
              <h3 className="card-title">{post.title}</h3>
              <a href={post.url} className="card-link">Read more</a>
            </div>
          ))}
        </div>
      </div> */}
      <Contact />
      <Footer />
      <BackToTopButton />
    </>
  );
}
