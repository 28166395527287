import React from "react";
import "../styles/nav.css";
import { Link as RouterLink } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
import { useState } from "react";

export default function Nav() {
  const scrollToContact = () => {
    scroll.scrollTo("contactSection", {
      smooth: true,
      duration: 500,
      offset: -70, // Adjust this offset as needed to position the section correctly
    });
  };

  const scrollToProjects = () => {
    scroll.scrollTo("projectsSection", {
      smooth: true,
      duration: 500,
      offset: -70, // Adjust this offset as needed to position the section correctly
    });
  };

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [activeButton, setActiveButton] = useState("");

  const handleMouseEnter = (name) => {
    setDropdownVisible(true);
    setActiveButton(name);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  // Inside your component
  const Industry = [
    "Construction",
    "Agriculture",
    "Supply Chain",
    "HealthCare",
    "Real Estate",
    "Education",
    "Ecommerce",
    "Retail",
    "Travel & Transportation",
    "FinTech",
    "Food & Grocery",
  ]; // Add more service names if needed

  return (
    <>
      <nav class="navbar navbar-expand-lg bg-body-white shadow">
        <div class="container">
          <div class="extra-container container-fluid">
            <div className="d-flex justify-content-between">
              <RouterLink to={"/"} class="navbar-brand">
                <img
                  src={process.env.PUBLIC_URL + "/assets/logo.png"}
                  alt="Logo of Micro IT Industry"
                  class="d-inline-block align-text-top"
                  width={190}
                  height={65}
                />
              </RouterLink>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo03"
                aria-controls="navbarTogglerDemo03"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>

            <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
              <div class="d-flex" role="search">
                <ul class="navbar-nav mb-2 mb-lg-0">
                  <li
                    style={{ marginRight: "20px" }}
                    className="d-flex flex-column flex-md-row justify-content-start justify-content-between mb-3 ml-md-0 ml-4 mt-4 py-2.5 w-100"
                  >
                    <div
                      className="dropdown"
                      onMouseEnter={() => handleMouseEnter("Service")}
                      onMouseLeave={handleMouseLeave}
                    >
                      <button
                        className="btnService btn text-gray-700 text-xl dropdown-toggle"
                        type="button"
                        aria-expanded={isDropdownVisible ? "true" : "false"}
                        style={{
                          color: isDropdownVisible && activeButton === "Service" ? "#0067dc" : "#000"
                        }}
                      >
                        Services
                      </button>
                      <ul
                        className={`dropdown-menu dropdown-menu-lg ${isDropdownVisible && activeButton == "Service"
                            ? "show"
                            : ""
                          }`}
                        style={{ width: "16rem" }}
                        aria-labelledby="servicesDropdown"
                      >
                        <li className="dropdown-item-li">
                          <a className="dropdown-item-anchor" href="/web-development-services">
                            <RouterLink
                              to={"/web-development-services"}
                              className="dropdown-item-anchor"
                            >
                              Web Development
                            </RouterLink>

                          </a>
                        </li>
                        <li className="dropdown-item-li">
                          <a className="dropdown-item-anchor">
                            Cyber Security
                          </a>
                        </li>
                        <li className="dropdown-item-li">
                          <a className="dropdown-item-anchor">
                            Blockchain
                          </a>
                        </li>
                        <li className="dropdown-item-li">
                          <a className="dropdown-item-anchor">
                            AI/ML
                          </a>
                        </li>
                        <li className="dropdown-item-li">
                          <a className="dropdown-item-anchor">
                            Mobile App
                          </a>
                        </li>
                        <li className="dropdown-item-li">
                          <a className="dropdown-item-anchor">
                            Wordpress/Wix
                          </a>
                        </li>
                        <li className="dropdown-item-li">
                          <a className="dropdown-item-anchor">
                            UI/UX Design
                          </a>
                        </li>
                        <li className="dropdown-item-li">
                          <a className="dropdown-item-anchor">
                            Software Automation
                          </a>
                        </li>
                        <li className="dropdown-item-li">
                          <a className="dropdown-item-anchor">
                            Custom Software Development
                          </a>
                        </li>
                        <li className="dropdown-item-li">
                          <a className="dropdown-item-anchor">
                            Search Engine Optimization
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div
                      className="dropdown"
                      onMouseEnter={() => handleMouseEnter("industry")}
                      onMouseLeave={handleMouseLeave}
                    >
                      <button
                        className="btnService btn text-gray-700 text-xl dropdown-toggle"
                        type="button"
                        aria-expanded={isDropdownVisible ? "true" : "false"}
                        style={{
                          color: isDropdownVisible && activeButton === "industry" ? "#0067dc" : "#000"
                        }}
                      >
                        Industry
                      </button>
                      <ul
                        className={`dropdown-menu dropdown-menu-lg ${isDropdownVisible && activeButton == "industry"
                            ? "show"
                            : ""
                          }`}
                        style={{ width: "16rem" }}
                        aria-labelledby="servicesDropdown"
                      >
                        {Industry.map((serviceName, index) => (
                          <li key={index} className="dropdown-item-li">
                            <a className="dropdown-item-anchor">
                              {serviceName}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div
                      className="dropdown"
                      onMouseEnter={() => handleMouseEnter("company")}
                      onMouseLeave={handleMouseLeave}
                    >
                      <button
                        className="btnService btn text-gray-700 text-xl dropdown-toggle "
                        type="button"
                        aria-expanded={isDropdownVisible ? "true" : "false"}
                        style={{
                          color: isDropdownVisible && activeButton === "company" ? "#0067dc" : "#000"
                        }}
                      >
                        Company
                      </button>
                      <ul

                        className={`dropdown-menu dropdown-menu-lg ${isDropdownVisible && activeButton == "company"
                            ? "show"
                            : ""
                          }`}
                        style={{ width: "16rem" }}
                        aria-labelledby="servicesDropdown"
                      >
                        <li className="dropdown-item-li">
                          <RouterLink
                            to={"/blogs"}
                            className="dropdown-item-anchor"
                          >
                            Blogs
                          </RouterLink>
                        </li>

                        <li className="dropdown-item-li">
                          <a className="dropdown-item-anchor">
                            <Link
                              to="projectsSection" // This should match the 'name' prop in the Contact section
                              smooth={true}
                              duration={500}
                              offset={-70}
                              onClick={scrollToProjects}
                            >
                              Portfolio
                            </Link>
                          </a>
                        </li>

                        <li className="dropdown-item-li">
                          <a className="dropdown-item-anchor">About Us</a>
                        </li>

                        <li className="dropdown-item-li">
                          <RouterLink
                            to={"/team"}
                            className="dropdown-item-anchor"
                          >
                            Team
                          </RouterLink>
                        </li>

                        <li className="dropdown-item-li">
                          <a className="dropdown-item-anchor">Career</a>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <div>
                    <Link
                      to="contactSection" // This should match the 'name' prop in the Contact section
                      smooth={true}
                      duration={500}
                      offset={-70} // Adjust this offset as needed to position the section correctly
                      className="mainContactBtn btn btn-primary bg-gradient1 color-2 font-weight-bold mb-8"
                      style={{
                        minWidth: "166px",
                        marginTop: "20px",
                        cursor: "pointer",
                        fontSize: "1.2rem",
                      }}
                      onClick={scrollToContact}
                    >
                      Contact Us
                    </Link>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
