import React from "react";
import "../styles/develop.css";
import useScrollAnimation from "../custom-hook/scrolleranimation";

export default function Development() {
  const containerRef = useScrollAnimation();
  return (
    <>
      <div className="development bg-light">
        <div className="line-d"></div>
        <div className="semi-line-d"></div>
        <div className="reviews_heading" ref={containerRef}>
          <div className="borderlinemid"></div>
          <h1 className="mt-3 d-flex justify-content-center">Our Design &</h1>
          <h1
            className="d-flex justify-content-center"
            style={{ fontWeight: "700" }}
          >
            Development Approach
          </h1>
        </div>

        <div className="container" ref={containerRef}>
          <div className="mt-3 row justify-content-center">
            <div className="col-lg-6">
              <div class="card mt-2 service">
                <div class="card-body d-flex flex-row">
                  <div
                    className="mt-1"
                    style={{
                      height: "40px",
                      maxWidth: "40px",
                      background: "#212529e0",
                      borderRadius: "10%",
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/assets/rocket.png"}
                      alt="UX Driven Engineering"
                      className="img-fluid rounded"
                    />
                  </div>
                  <div style={{ marginLeft: "1rem" }}>
                    <h3>UX Driven Engineering</h3>
                    <p>
                      Micro IT Industry prioritizes user experience (UX) in the
                      development process, ensuring that software and
                      applications are user-friendly and intuitive.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="card mt-2 service">
                <div class="card-body d-flex flex-row">
                  <div
                    className="mt-1"
                    style={{
                      height: "40px",
                      maxWidth: "40px",
                      background: "#509CF5",
                      borderRadius: "10%",
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/assets/code.png"}
                      alt="Developing Shared Understanding"
                      className="img-fluid rounded"
                    />
                  </div>
                  <div style={{ marginLeft: "1rem" }}>
                    <h3>Developing Shared Understanding</h3>
                    <p>
                      We focuses on establishing clear communication and
                      alignment between its team and clients to ensure everyone
                      has a common vision for the project.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 row justify-content-center">
            <div className="col-lg-6">
              <div class="card mt-2 service">
                <div class="card-body d-flex flex-row">
                  <div
                    className="mt-1"
                    style={{
                      height: "40px",
                      maxWidth: "40px",
                      background: "#FF3D9A",
                      borderRadius: "10%",
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/assets/heart.png"}
                      alt="Proven Experience and Expertise"
                      className="img-fluid rounded"
                    />
                  </div>

                  <div style={{ marginLeft: "1rem" }}>
                    <h3>Proven Experience and Expertise</h3>
                    <p>
                      We brings a wealth of knowledge and proficiency to its
                      projects, drawing on past successes and expertise to
                      deliver high-quality solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="card mt-2 service">
                <div class="card-body d-flex flex-row">
                  <div
                    className="mt-1"
                    style={{
                      height: "40px",
                      maxWidth: "40px",
                      background: "#24E795",
                      borderRadius: "10%",
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/assets/shield.png"}
                      alt="Security & Intellectual Property (IP)"
                      className="img-fluid rounded"
                    />
                  </div>
                  <div style={{ marginLeft: "1rem" }}>
                    <h3>Security & Intellectual Property (IP)</h3>
                    <p>
                   We strongly emphasis on security and protecting intellectual property, safeguarding both client data and proprietary software elements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 row justify-content-center">
            <div className="col-lg-6">
              <div class="card mt-2 service">
                <div class="card-body d-flex flex-row">
                  <div
                    className="mt-1"
                    style={{
                      height: "40px",
                      maxWidth: "40px",
                      background: "#F7936F",
                      borderRadius: "10%",
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/assets/tick.png"}
                      alt="Code Reviews"
                      className="img-fluid rounded"
                    />
                  </div>
                  <div style={{ marginLeft: "1rem" }}>
                    <h3>Code Reviews</h3>
                    <p>
                    We conducts thorough code reviews to maintain code quality, identify and address issues promptly, and ensure the software's reliability and maintainability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="card mt-2 service">
                <div class="card-body d-flex flex-row">
                  <div
                    className="mt-1"
                    style={{
                      height: "40px",
                      maxWidth: "40px",
                      background: "#F76680",
                      borderRadius: "10%",
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/assets/lock.png"}
                      alt="Quality Assurance & Testing"
                      className="img-fluid rounded"
                    />
                  </div>
                  <div style={{ marginLeft: "1rem" }}>
                    <h3>Quality Assurance & Testing</h3>
                    <p>
                      We employs rigorous quality assurance and testing procedures to identify and rectify any defects or performance issues, guaranteeing that the final product meets high-quality standards.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "70px" }}></div>
      </div>
    </>
  );
}
