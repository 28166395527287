import React, { useEffect, useState } from "react";
import Nav from "../components/nav";
import "../styles/blog-list.css";
import { Link } from "react-router-dom";
import Contact from "../components/contact";
import Footer from "../components/Footer";
import BackToTopButton from "../components/BackToTopButton";
import { Helmet } from 'react-helmet-async';

export default function BlogList() {
  const [blogs] = useState([
    {
      id: 1,
      title: "Top NON EVM Blockchain Plateforms",
      date: "May 19, 2024",
      author: {
        name: "",
        imageUrl: ""
      },
      url:"/top-non-evm-blockchain-plateforms",
      image: "/assets/blogs/top-non-evm-thumbnail.png"
    },
  ]);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage] = useState(10);

  // Pagination
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ogData = {
    type: "website",
    title: "Latest Blogs: Micro IT Industry",
    description:
      "Latest blogs on technology, business, ideas, best software practices and designing",
    url: "https://www.microitindustry.com/blogs",
    image: "/public/assets/blogs/top-non-evm-thumbnail.png",
    siteName: "Micro IT Industry",
  };

  return (
    <>
    <Helmet>
        <title>{ogData.title}</title>
        <meta property="og:type" content={ogData.type} />
        <meta property="og:title" content={ogData.title} />
        <meta property="og:description" content={ogData.description} />
        <meta property="og:url" content={ogData.url} />
        <meta property="og:image" content={ogData.image} />
        <meta property="og:site_name" content={ogData.siteName} />
        <meta name="description" content={ogData.description} />
      </Helmet>
      <Nav />
      <div className="container">
        <h1 className="title">Latest <span>Blogs</span></h1>
            <div className="line-b"></div>
        <input
          type="text"
          placeholder="Search blogs..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <div className="blog-list">
          {currentBlogs
            .filter((blog) =>
              blog.title.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((blog) => (
              <div key={blog.id} className="blog-card">
                <Link to={`/blogs${blog.url}`} className="blog-link">
                  <img
                    src={blog.image}
                    alt={blog.title}
                    className="blog-image"
                  />
                  <div className="blog-details">
                    <h2>{blog.title}</h2>
                    <p className="author-b">{blog.date}</p>
                    <div className="author-b">
                      {/* <img
                        src={blog.author.imageUrl}
                        alt={blog.author.name}
                        className="author-image"
                      />
                      <p>{blog.author.name}</p> */}
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
        <Pagination
          blogsPerPage={blogsPerPage}
          totalBlogs={blogs.length}
          paginate={paginate}
        />
      </div>
      <Contact />
      <Footer />
      <BackToTopButton />
    </>
  );
}

const Pagination = ({ blogsPerPage, totalBlogs, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalBlogs / blogsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li key={number} className="page-item">
            <button onClick={() => paginate(number)} className="page-link">
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};
