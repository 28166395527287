// useScrollAnimation.js
import { useEffect, useRef } from 'react';

const useScrollAnimation = () => {
  const elementRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-in');
        } else {
          entry.target.classList.remove('slide-in');
        }
      });
    });

    observer.observe(elementRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return elementRef;
};

export default useScrollAnimation;
