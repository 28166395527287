import React, { useState, useEffect } from "react";
import "../styles/tech.css";

export default function Techstack() {
  const [activeButton, setActiveButton] = useState("Frontend");
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
    const timeoutId = setTimeout(() => setAnimate(false), 500); // Duration of the animation
    return () => clearTimeout(timeoutId);
  }, [activeButton]);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const getButtonStyles = (buttonName) => ({
    fontSize: "1.3rem",
    color: activeButton === buttonName ? "#0067dc" : "initial",
    fontWeight: activeButton === buttonName ? 600 : "normal",
    borderBottom: activeButton === buttonName ? "4px solid #0067dc" : "none",
  });

  const techStacks = {
    Frontend: [
      { src: "/assets/html.png", alt: "HTML" },
      { src: "/assets/css.webp", alt: "CSS" },
      { src: "/assets/js.png", alt: "JavaScript" },
      { src: "/assets/react.svg", alt: "React" },
      { src: "/assets/angular.png", alt: "Angular" },
    ],
    Backend: [
      { src: "/assets/rust.png", alt: "Rust" },
      { src: "/assets/PHP.png", alt: "PHP" },
      { src: "/assets/Go.png", alt: "Golang" },
      { src: "/assets/django.webp", alt: "Django" },
      { src: "/assets/RubyOnRails.png", alt: "Ruby on Rails" },
      { src: "/assets/Java.png", alt: "Java" },
      { src: "/assets/dot-net.webp", alt: ".NET" },
    ],
    "Artificial Intelligence": [
      { src: "/assets/Anaconda.webp", alt: "Anaconda" },
      { src: "/assets/tensorflow.png", alt: "TensorFlow" },
      { src: "/assets/Python.png", alt: "Python" },
      { src: "/assets/scikit.png", alt: "Scikitlearn" },
      { src: "/assets/jupyter.png", alt: "Jupyter" },
    ],
    Cybersecurity: [
      { src: "/assets/burp.png", alt: "Burp Suite" },
      { src: "/assets/metas.png", alt: "Metasploit" },
      { src: "/assets/npam.png", alt: "Nmap" },
      { src: "/assets/wireshark.png", alt: "Wireshark" },
    ],
    Blockchain: [
      { src: "/assets/etheru.png", alt: "Ethereum" },
      { src: "/assets/solidity.png", alt: "Solidity" },
      { src: "/assets/stellar.png", alt: "Stellar" },
      { src: "/assets/ipfs.png", alt: "IPFS" },
      { src: "/assets/output.png", alt: "Blockchain" },
      { src: "/assets/solana.png", alt: "Solana" },
    ],
    Mobile: [
      { src: "/assets/flutter-logo.png", alt: "Flutter" },
      { src: "/assets/dart-logo.png", alt: "Dart" },
      { src: "/assets/react.svg", alt: "React Native" },
      { src: "/assets/android.png", alt: "Android" },
      { src: "/assets/kotlin-logo.png", alt: "Kotlin" },
      { src: "/assets/java-logo.webp", alt: "Java" },
      { src: "/assets/swift_logo.svg", alt: "Swift" },
      { src: "/assets/apple-app-store.png", alt: "Apple App Store" },
    ],
  };

  return (
    <section className="techstack">
      <div className="line-t"></div>
      <div className="semi-line-t"></div>
      <header className="reviews_heading">
        <div className="borderlinemid"></div>
        <h1 className="mt-3 text-center">Our</h1>
        <h1 className="text-center" style={{ fontWeight: "700" }}>Tech Stack</h1>
      </header>
      <div className="container">
        <div className="row">
          <div className="tech-section d-flex justify-content-center flex-wrap">
            {Object.keys(techStacks).map((tech) => (
              <button
                key={tech}
                style={getButtonStyles(tech)}
                className={`stack m-2 btn ${activeButton === tech ? "active" : ""}`}
                onClick={() => handleButtonClick(tech)}
              >
                {tech}
              </button>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="mt-5 col-lg-12">
            {techStacks[activeButton] && (
              <div className="stack-img d-flex justify-content-center flex-wrap" id={activeButton.replace(/\s+/g, '_')}>
                {techStacks[activeButton].map((techItem, index) => (
                  <div key={index} className={`tech-item tech-item-${techItem.alt.toLowerCase().replace(/\s+/g, '-')} ${animate ? 'animate' : ''} mobile-rsp`}>
                    <img src={process.env.PUBLIC_URL + techItem.src} alt={techItem.alt} className="tech-img" />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{ height: "50px" }}></div>
    </section>
  );
}
