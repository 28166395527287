import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "../styles/contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faPhone } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";

import env from "react-dotenv";

export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault(); // prevents the page from reloading when you hit “Send”

    console.log("process.env.SERVICE_ID", env.SERVICE_ID);
    console.log("process.env.TEMPLATE_ID", env.TEMPLATE_ID);
    console.log("process.env.API_KEY", env.API_KEY);

    emailjs.sendForm(env.SERVICE_ID, env.TEMPLATE_ID, form.current, env.API_KEY).then(
      (result) => {
        alert("Email Sent Successfully!")
      },
      (error) => {
        alert("Erorr in sending the email")
      }
    );
  };

  return (
    <>
      <div className="contact" name="contactSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="d-flex flex-column gap-7 items-start justify-start w-auto md:w-full content"
                style={{ marginTop: "1rem" }}
              >
                <div className="borderlinec"></div>
                <h1 className="mt-3" style={{ fontSize: "3rem", color: "black" }}>
                  Let's build something 
                  <span style={{ fontWeight: "700" }}> awesome together </span>
                </h1>
              </div>

              <form ref={form} onSubmit={sendEmail}>
                <input type="text" placeholder="Name" name="from_name" className="form-control mt-1 inputCt" required />
                <input
                  type="email"
                  placeholder="Email"
                  name="from_email"
                  className="form-control mt-3 inputCt"
                  required
                />
                <input
                  type="tel"
                  placeholder="Phone"
                  name="from_phone"
                  className="form-control mt-3 inputCt"
                  required
                />

                <textarea
                  placeholder="Message"
                  name="message"
                  className="form-control mt-3"
                  style={{ height: "150px" }}
                />

                <input style={{float: "right"}} className="btn btn-primary mt-3 mb-5 bg-gradient1 color-2" type="submit" value="Send Message" />
              </form>
            </div>
            <div className="col-lg-6">
              <div className="p-5 d-flex flex-column contactRight1" style={{ marginTop: "8rem", marginLeft: "9rem" }}>
                <div className="container d-flex flex-row">
                  <div className="p-3 bg-white shadow rounded" style={{ height: "60px" }}>
                    <FontAwesomeIcon icon={faBuilding} size="2xl" />
                  </div>
                  <div style={{ marginLeft: "1rem" }}>
                    <h3>Visit Us</h3>
                    <p>Lahore, Pakistan 🇵🇰</p>
                  </div>
                </div>
              </div>
              <div className="p-5 d-flex flex-column contactRight2" style={{ marginLeft: "9rem" }}>
                <div className="container d-flex flex-row">
                  <div className="p-3 bg-white shadow rounded" style={{ height: "60px" }}>
                    <FontAwesomeIcon icon={faPhone} size="2xl" />
                  </div>
                  <div style={{ marginLeft: "1rem" }}>
                    <h3>Drop us a line</h3>
                    <p>microitindustry@gmail.com </p>
                    <p>🇵🇰 PK +92 3157 466519</p>
                    <p>🇬🇧 UK +44 7919 483409</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
