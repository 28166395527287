import React from "react";
import "../styles/second.css";
import { Link as RouterLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaintBrush,
  faInfinity,
  faShieldVirus,
  faBrain,
  faComputer,
  faMobileAndroid,
  faCode,
  faFileWord,
  faSearch,
  faNetworkWired,
} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Make sure to import the CSS
import useScrollAnimation from "../custom-hook/scrolleranimation";

export default function Secondsection() {
  const containerRef = useScrollAnimation();

  return (
    <>
      <div className="mt-4 secondsection">
        <h1
          style={{ color: "black", marginTop: "30px" }}
          ref={containerRef}
          className="text-center text-black "
        >
          Technology Consulting &{" "}
          <span style={{ fontWeight: "700" }}>Development Services</span>
        </h1>
        <div
          className="container"
          ref={containerRef}
          style={{ marginTop: "25px" }}
        >
          <div className="row justify-content-center">
            <div class="card mt-2 service" style={{ maxWidth: "320px" }}>
              <div class="card-body">
                <div class="box d-flex flex-column justify-content-center">
                  <FontAwesomeIcon
                    className="mt-3"
                    icon={faShieldVirus}
                    size="2xl"
                  />
                  <h4 className="mt-3 text-center" style={{ fontSize: "21px" }}>Cyber Security</h4>
                </div>
              </div>
            </div>
            <div class="card mt-2 service" style={{ maxWidth: "320px" }}>
              <div class="card-body">
                <div class="box d-flex flex-column justify-content-center">
                  <FontAwesomeIcon
                    className="mt-3"
                    icon={faNetworkWired}
                    size="2xl"
                  />
                  <h4 className="mt-3 text-center" style={{ fontSize: "21px" }}>Blockchain Development</h4>
                </div>
              </div>
            </div>
            <div class="card mt-2 service" style={{ maxWidth: "320px" }}>
              <div class="card-body">
                <div class="box d-flex flex-column justify-content-center">
                  <FontAwesomeIcon className="mt-3" icon={faBrain} size="2xl" />
                  <h4 className="mt-3 text-center" style={{ fontSize: "21px" }}>AI/ML</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 row justify-content-center">

            <div class="card mt-2 service" style={{ maxWidth: "320px" }}>
              <RouterLink
                to={"/web-development-services"}
                className="second-router"
              >
                <div class="card-body">
                  <div class="box d-flex flex-column justify-content-center">
                    <FontAwesomeIcon className="mt-3" icon={faCode} size="2xl" />
                    <h4 className="mt-3 text-center" style={{ fontSize: "21px" }} >
                      Web Development
                    </h4>
                  </div>
                </div>
              </RouterLink>
            </div>

            <div class="card mt-2 service" style={{ maxWidth: "320px" }}>
              <div class="card-body">
                <div class="box d-flex flex-column justify-content-center">
                  <FontAwesomeIcon
                    className="mt-3"
                    icon={faMobileAndroid}
                    size="2xl"
                  />
                  <h4 className="mt-3 text-center" style={{ fontSize: "21px" }}   >
                    Mobile App Development
                  </h4>
                </div>
              </div>
            </div>
            <div class="card mt-2 service" style={{ maxWidth: "320px" }}>
              <div class="card-body">
                <div class="box d-flex flex-column justify-content-center">
                  <FontAwesomeIcon
                    className="mt-3"
                    icon={faFileWord}
                    size="2xl"
                  />
                  <h4 className="mt-3 text-center" style={{ fontSize: "21px" }}>
                    WordPress Development
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 row justify-content-center">


            <div class="card mt-2 service" style={{ maxWidth: "320px" }}>
              <div class="card-body">
                <div class="box d-flex flex-column justify-content-center">
                  <FontAwesomeIcon
                    className="mt-3"
                    icon={faInfinity}
                    size="2xl"
                  />
                  <h4 className="mt-3 text-center" style={{ fontSize: "21px" }}>Software Automation</h4>
                </div>
              </div>
            </div>
            <div class="card mt-2 service" style={{ maxWidth: "320px" }}>
              <div class="card-body">
                <div class="box d-flex flex-column justify-content-center">
                  <FontAwesomeIcon
                    className="mt-3"
                    icon={faComputer}
                    size="2xl"
                  />
                  <h4 className="mt-3 text-center" style={{ fontSize: "17px" }} >
                    Custom Software Development
                  </h4>
                </div>
              </div>
            </div>
            <div class="card mt-2 service" style={{ maxWidth: "320px" }}>
              <div class="card-body">
                <div class="box d-flex flex-column justify-content-center">
                  <FontAwesomeIcon className="mt-3" icon={faPaintBrush} size="2xl" />
                  <h4 className="mt-3 text-center" style={{ fontSize: "21px" }}>UI/UX Design</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3 row justify-content-center mb-5">
            <div class="card mt-2 service" style={{ maxWidth: "320px" }}>
              <div class="card-body">
                <div class="box d-flex flex-column justify-content-center">
                  <FontAwesomeIcon className="mt-3" icon={faSearch} size="2xl" />
                  <h4 className="mt-3 text-center" style={{ fontSize: "21px" }}>Search Engine Optimization</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
