import React from "react";
import { Link as RouterLink } from "react-router-dom";
import "../styles/footer.css";

export default function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container" style={{ marginTop: "5rem" }}>
          <div className="row">
            <div className="col-lg-4">
              <a class="navbar-brand" href="/">
                <img src={process.env.PUBLIC_URL + "/assets/logo.png"} alt="Logo of Micro IT Industry" class="d-inline-block align-text-top" />
              </a>
              <p style={{ marginTop: "2rem", color: "#718096" }}>
                Micro IT Industry is a leading software company that offers a range of services to help businesses stay
                competitive in today's digital age.
              </p>
            </div>
            <div className="col-lg-4 d-flex justify-content-center">
              <div style={{ marginTop: "10px" }} className="linksFooter">
                <a className="footer_link" href="#">
                  About Us
                </a>{" "}
                <br />
                <a className="footer_link" href="#">
                  Services
                </a>{" "}
                <br />
                <a className="footer_link" href="#">
                  Portfolio
                </a>{" "}
                <br />
                <RouterLink className="footer_link" to={"/team"}>
                
                  Team
               
                </RouterLink>
               
                <br />
                <a className="footer_link" href="#">
                  Blog
                </a>{" "}
                <br />
                <a className="footer_link" href="#">
                  Careers
                </a>{" "}
                <br />
              </div>
            </div>
            <div className="col-lg-4 colContactF">
              <h3 style={{ color: "#4A5568" }} className="contactusFooter">
                Contact Us
              </h3>
              <p style={{ marginTop: "20px", color: "#718096" }}>microitindustry@gmail.com</p>
              <p style={{ color: "#718096" }}>🇵🇰 PK +92 3157 466519</p>
              <div className="d-flex flex-row">
                <a href="https://www.facebook.com/MicroITIndustryOfficial" target="_blank">
                  <div className="footerLinks fbF">
                    <svg width="15" height="15" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_226_237)">
                        <path
                          d="M7.9985 1.9925H9.094V0.0845C8.905 0.0585 8.255 0 7.498 0C5.91851 0 4.83651 0.9935 4.83651 2.8195V4.5H3.09351V6.633H4.83651V12H6.97351V6.6335H8.646L8.9115 4.5005H6.97301V3.031C6.97351 2.4145 7.1395 1.9925 7.9985 1.9925Z"
                          fill="#1A202C"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_226_237">
                          <rect width="12" height="12" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </a>

                <a href="https://www.instagram.com/micro_it_industry_miti" target="_blank">
                  <div className="footerLinks instagramF">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 13 13" fill="none">
                      <g clip-path="url(#clip0_226_242)">
                        <path
                          d="M9.481 0H3.51898C1.57858 0 0 1.57858 0 3.51898V9.4811C0 11.4214 1.57858 13 3.51898 13H9.4811C11.4214 13 13 11.4214 13 9.4811V3.51898C13 1.57858 11.4214 0 9.481 0V0ZM12.2379 9.4811C12.2379 11.0012 11.0012 12.2379 9.481 12.2379H3.51898C1.99881 12.2379 0.762114 11.0012 0.762114 9.4811V3.51898C0.762114 1.99881 1.99881 0.762114 3.51898 0.762114H9.4811C11.0012 0.762114 12.2379 1.99881 12.2379 3.51898V9.4811Z"
                          fill="#1A202C"
                        />
                        <path
                          d="M6.50002 2.94531C4.53998 2.94531 2.94543 4.53986 2.94543 6.49989C2.94543 8.45993 4.53998 10.0545 6.50002 10.0545C8.46005 10.0545 10.0546 8.45993 10.0546 6.49989C10.0546 4.53986 8.46005 2.94531 6.50002 2.94531ZM6.50002 9.29236C4.96032 9.29236 3.70755 8.03969 3.70755 6.49989C3.70755 4.96019 4.96032 3.70743 6.50002 3.70743C8.03982 3.70743 9.29248 4.96019 9.29248 6.49989C9.29248 8.03969 8.03982 9.29236 6.50002 9.29236Z"
                          fill="#1A202C"
                        />
                        <path
                          d="M10.1396 1.68311C9.56035 1.68311 9.08923 2.15432 9.08923 2.73344C9.08923 3.31266 9.56035 3.78388 10.1396 3.78388C10.7188 3.78388 11.19 3.31266 11.19 2.73344C11.19 2.15422 10.7188 1.68311 10.1396 1.68311ZM10.1396 3.02166C9.98068 3.02166 9.85135 2.89233 9.85135 2.73344C9.85135 2.57445 9.98068 2.44522 10.1396 2.44522C10.2986 2.44522 10.4279 2.57445 10.4279 2.73344C10.4279 2.89233 10.2986 3.02166 10.1396 3.02166Z"
                          fill="#1A202C"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_226_242">
                          <rect width="13" height="13" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </a>

                <a href="https://twitter.com/microitindustry" target="_blank">
                  <div className="footerLinks twitterF">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 13 13" fill="none">
                      <g clip-path="url(#clip0_226_249)">
                        <path
                          d="M13 2.46919C12.5166 2.68125 12.0014 2.82181 11.4644 2.89006C12.0169 2.56019 12.4386 2.04181 12.6368 1.417C12.1217 1.72412 11.5529 1.94106 10.9468 2.06213C10.4577 1.54131 9.76056 1.21875 9.00006 1.21875C7.52456 1.21875 6.33669 2.41638 6.33669 3.88456C6.33669 4.09581 6.35456 4.29894 6.39844 4.49231C4.18275 4.38425 2.22219 3.32231 0.905125 1.70463C0.675187 2.10356 0.540313 2.56019 0.540313 3.05175C0.540313 3.97475 1.01562 4.79294 1.72413 5.26662C1.29594 5.2585 0.875875 5.13419 0.52 4.93837C0.52 4.9465 0.52 4.95706 0.52 4.96762C0.52 6.26275 1.44381 7.3385 2.65525 7.58631C2.43831 7.64563 2.20187 7.67406 1.9565 7.67406C1.78587 7.67406 1.61362 7.66431 1.45194 7.62856C1.79725 8.684 2.77713 9.45994 3.94225 9.48512C3.0355 10.1944 1.88419 10.6218 0.637812 10.6218C0.41925 10.6218 0.209625 10.6121 0 10.5852C1.18056 11.3466 2.57969 11.7812 4.0885 11.7812C8.99275 11.7812 11.674 7.71875 11.674 4.19737C11.674 4.07956 11.6699 3.96581 11.6643 3.85287C12.1932 3.4775 12.6376 3.00869 13 2.46919Z"
                          fill="#1A202C"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_226_249">
                          <rect width="13" height="13" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </a>

                <a href="https://www.linkedin.com/company/micro-it-industry" target="_blank">
                  <div className="footerLinks linkedinF">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 11 11" fill="none">
                      <g clip-path="url(#clip0_226_256)">
                        <path
                          d="M10.9973 10.9998V10.9993H11V6.96507C11 4.99148 10.5751 3.47119 8.26789 3.47119C7.15873 3.47119 6.41439 4.07986 6.11052 4.6569H6.07843V3.65544H3.89081V10.9993H6.16873V7.3629C6.16873 6.40544 6.35023 5.47961 7.53593 5.47961C8.70423 5.47961 8.72164 6.57228 8.72164 7.42432V10.9998H10.9973Z"
                          fill="#1A202C"
                          className="linkedinF"
                        />
                        <path d="M0.181519 3.65625H2.46219V11.0001H0.181519V3.65625Z" fill="#1A202C" />
                        <path
                          d="M1.32092 0C0.591708 0 0 0.591708 0 1.32092C0 2.05013 0.591708 2.65421 1.32092 2.65421C2.05013 2.65421 2.64183 2.05013 2.64183 1.32092C2.64138 0.591708 2.04967 0 1.32092 0V0Z"
                          fill="#1A202C"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_226_256">
                          <rect width="11" height="11" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="lineF"></div>

        <div>
          <p style={{ textAlign: "center", color: "#718096", marginTop: "1rem" }}>
            © 2024 Micro IT Industry. All Rights Reserved.
          </p>
        </div>
      </div>
    </>
  );
}
