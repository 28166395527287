import React, { useEffect } from "react";
import Nav from '../components/nav'
import "../styles/home.css"
import Firstsection from '../components/Firstsection'
import Secondsection from '../components/Secondsection'
import Thirdsection from '../components/Thirdsection'
import Reviews from '../components/reviews'
import Project from '../components/projectsection'
import Working from '../components/Workingsection'
import Development from '../components/development'
import Footer from '../components/Footer'
import Contact from '../components/contact'
import Slideline from '../components/Slideline'
import Techstack from '../components/Techstack'
import BackToTopButton from '../components/BackToTopButton'

export default function Home() {

  useEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
    <Nav />
    <Firstsection />
    <Secondsection />
    {/* <Thirdsection /> */}
    <Reviews />
    <Project/>
    {/* <Working /> */}
    <Development />
    <Techstack />
    <Slideline />
    <Contact />
    <Footer />
    <BackToTopButton />
    </>
  )
}
