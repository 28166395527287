import React, { useEffect } from "react";
import Nav from "../components/nav";
import "../styles/teams.css";
import Contact from "../components/contact";
import Footer from "../components/Footer";
import useScrollAnimation from "../custom-hook/scrolleranimation";
import BackToTopButton from "../components/BackToTopButton";
import { Helmet } from 'react-helmet-async';

export default function Teams() {
  const containerRef = useScrollAnimation();


  const canonicalUrl =
  "https://www.microitindustry.com";
const ogData = {
  type: "website",
  title: "Team | Micro IT Industry",
  description: "Micro IT Industry is a leading software company that offers a range of services to help businesses stay competitive in today's digital age. Contact us today!",
  url: "https://www.microitindustry.com/team",
  image: "/public/assets/logo.png",
  siteName: "Micro IT Industry",
};

  useEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <Helmet>
        {/* Canonical Tag */}
        <link rel="canonical" href={canonicalUrl} />
        <title>{ogData.title}</title>


        {/* Open Graph Meta Tags */}
        <meta property="og:type" content={ogData.type} />
        <meta property="og:title" content={ogData.title} />
        <meta property="og:description" content={ogData.description} />
        <meta property="og:url" content={ogData.url} />
        <meta property="og:image" content={ogData.image} />
        <meta property="og:site_name" content={ogData.siteName} />
        {/* You can add more Open Graph tags as needed */}

        {/* Other standard meta tags */}
        <meta name="description" content={ogData.description} />
      </Helmet>
      <Nav />
      <div>
        <div className="container">
          <div className="reviews_heading" ref={containerRef} style={{ marginTop: "5px" }}>
            {/* <div className="borderlinemid"></div> */}
            <h1 className="mt-5 d-flex justify-content-center">
              Meet
              <span className="d-flex justify-content-center" style={{ marginLeft: "10px", fontWeight: "700" }}>
                Our Leaders
              </span>
            </h1>
            <p className="text-center" style={{ fontSize: "20px" }}>
              Micro IT Industry has build and manage a team of world-class engineers to bring your vision into real
              life.
            </p>
          </div>
          <div className="container" style={{ marginTop: "20px" }}>
            <div className="row" ref={containerRef}>
              <div className="col-lg-6 d-flex mainCardT justify-content-end">
                <div class="card card1T" style={{ width: "fit-content" }}>
                  <img
                    class="card-img-top team-img"
                    src={process.env.PUBLIC_URL + "/assets/zaryab.png"}
                    alt="Muhammad Zaryab Rafique is Founder of Micro IT Industry"
                  />
                  <div class="card-body">
                    <h5 class="card-title">Muhammad Zaryab Rafique</h5>
                    <p class="card-text">Founder</p>
                    <a
                      href="https://www.linkedin.com/in/mzaryabrafique"
                      target="_blank"
                      rel="noreferrer"
                      style={{ float: "right" }}
                    >
                      <div
                        className="p-2 shadow linkT"
                        style={{
                          background: "#000000",
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <svg
                          style={{}}
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="12"
                          viewBox="0 0 11 12"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_274_1200)">
                            <path
                              d="M10.9973 11.3035V11.303H11.0001V7.26878C11.0001 5.29519 10.5752 3.7749 8.26795 3.7749C7.15879 3.7749 6.41445 4.38357 6.11058 4.96061H6.07849V3.95915H3.89087V11.303H6.16879V7.66661C6.16879 6.70915 6.35029 5.78332 7.536 5.78332C8.70429 5.78332 8.7217 6.87599 8.7217 7.72803V11.3035H10.9973Z"
                              fill="white"
                            />
                            <path d="M0.181396 3.95996H2.46206V11.3038H0.181396V3.95996Z" fill="white" />
                            <path
                              d="M1.32092 0.303711C0.591708 0.303711 0 0.895419 0 1.62463C0 2.35384 0.591708 2.95792 1.32092 2.95792C2.05013 2.95792 2.64183 2.35384 2.64183 1.62463C2.64138 0.895419 2.04967 0.303711 1.32092 0.303711Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_274_1200">
                              <rect width="11" height="11" fill="white" transform="translate(0 0.303711)" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex mainCardT justify-content-start">
                <div class="card card2T" style={{ width: "fit-content" }}>
                  <img
                    class="card-img-top team-img cardImg2"
                    src={process.env.PUBLIC_URL + "/assets/ehsan.png"}
                    alt="Ehsan Ahmad is Co-Founder of Micro IT Industry"
                  />
                  <div class="card-body">
                    <h5 class="card-title">Ehsan Ahmad</h5>
                    <p class="card-text">Co-Founder & Business Development</p>
                    <a
                      href="https://www.linkedin.com/in/ehsan-ahmad-"
                      target="_blank"
                      rel="noreferrer"
                      style={{ float: "right" }}
                    >
                      <div
                        className="p-2 shadow linkT"
                        style={{
                          background: "#000000",
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                          <g clip-path="url(#clip0_274_1200)">
                            <path
                              d="M10.9973 11.3035V11.303H11.0001V7.26878C11.0001 5.29519 10.5752 3.7749 8.26795 3.7749C7.15879 3.7749 6.41445 4.38357 6.11058 4.96061H6.07849V3.95915H3.89087V11.303H6.16879V7.66661C6.16879 6.70915 6.35029 5.78332 7.536 5.78332C8.70429 5.78332 8.7217 6.87599 8.7217 7.72803V11.3035H10.9973Z"
                              fill="white"
                            />
                            <path d="M0.181396 3.95996H2.46206V11.3038H0.181396V3.95996Z" fill="white" />
                            <path
                              d="M1.32092 0.303711C0.591708 0.303711 0 0.895419 0 1.62463C0 2.35384 0.591708 2.95792 1.32092 2.95792C2.05013 2.95792 2.64183 2.35384 2.64183 1.62463C2.64138 0.895419 2.04967 0.303711 1.32092 0.303711Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_274_1200">
                              <rect width="11" height="11" fill="white" transform="translate(0 0.303711)" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "60px" }}></div>
      <Contact />
      <Footer />
      <BackToTopButton />
    </>
  );
}
