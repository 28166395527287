import React from "react";
import "../styles/project.css";
import useScrollAnimation from "../custom-hook/scrolleranimation";

export default function Project() {
  const containerRef = useScrollAnimation();
  return (
    <>
      <div className="project bg-light" name="projectsSection">
        <div className="line"></div>
        <div className="semi-line"></div>

        <div className="container bg-light">
          <div className="row" ref={containerRef}>
            <div className="reviews_heading">
              <div className="borderlinemid"></div>
              <h1 className="mt-3 d-flex justify-content-center">Our Recent</h1>
              <h1 className="d-flex justify-content-center" style={{ fontWeight: "700" }}>
                Projects
              </h1>
            </div>
          </div>
          <div class="card d-flex flex-column mt-4 bg-light" style={{ width: "100%" }} ref={containerRef}>
            <div className="container border bg-light">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    class="card-img-top projectimg"
                    src={process.env.PUBLIC_URL + "/assets/projects/Builder.png"}
                    alt="Builder Software project portfolio"
                  
                  />
                </div>
                <div className="col-lg-6">
                  <div class="card-body ">
                    <h2>Builders Software System</h2>
                    <p class="card-text mt-4">
                    Builders Software System is the enterprise application to manage and optimize the construction workflows in the application, it includes accountancy, team management, project management, invoicing and estimating, site surveys & reports, letterheads and notes, seamless integration, reporting, document management, and application security. Builders Software System works on the Web App and Mobile App. 
                    </p>
                    <button style={{ float: "right" }} className="btn btn-primary text-white bg-gradient1 color-2 btnReadMore">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-4 border bg-light">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    class="card-img-top projectimg"
                    src={process.env.PUBLIC_URL + "/assets/projects/food-web-app.png"}
                    alt="Food Order and Delivery Web App portfolio"
                  />
                </div>
                <div className="col-lg-6">
                  <div class="card-body ">
                    <h2>Food Order & Delivery Web App</h2>
                    <p class="card-text mt-4">
                      The Food Order and Delivery Website is a dynamic web application developed using ASP.NET MVC,
                      aimed at providing a seamless online food ordering and delivery experience for customers while
                      offering efficient order management tools for restaurant owners. This platform enhances the
                      convenience of ordering food online and streamlines the operational processes for food vendors.
                      <br></br>
                      <b>Key Functionalities:</b>
                      <ul>
                        <li>
                          <b>Authentication: </b>Secure user login, signup, and password recovery.
                        </li>
                        <li>
                          <b>Admin Side: </b>Product management (CRUD) and order tracking.{" "}
                        </li>
                        <li>
                          <b>Customer Side: </b>Cart management, checkout, and easy browsing.
                        </li>
                        <li>
                          <b>Optimized Search: </b>Quick and efficient search functionality.
                        </li>
                      </ul>
                    </p>
                    <button style={{ float: "right" }} className="btn btn-primary text-white bg-gradient1 color-2 btnReadMore">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-4 border bg-light">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    class="card-img-top projectimg"
                    src={process.env.PUBLIC_URL + "/assets/projects/desktop-pos.png"}
                    alt="Inventory Management Software portfolio"
                  />
                </div>
                <div className="col-lg-6">
                  <div class="card-body ">
                    <h2>Inventory Management Software</h2>
                    <p class="card-text mt-4">
                      The Stationery Shop Desktop Application is a comprehensive software solution designed to
                      streamline and automate the operations of a stationary shop. Built using C# with Windows Forms,
                      this application leverages Microsoft SQL Server for data storage and SAP Crystal Reports for
                      reporting purposes.
                      <br></br>
                      <b>Key Features:</b>
                      <ul>
                        <li><b>Sale & Purchase:</b> Efficiently manage sales and purchases. Automatically update inventory.</li>
                        <li><b>Stock Handling:</b> Real-time inventory tracking. Low-stock alerts.</li>
                        <li><b>Custom Reporting:</b> Generate customized reports using SAP Crystal Reports.</li>
                        <li><b>Database Backup and Restore:</b> Secure data backup and restore functionality.</li>
                        <li><b>Email System:</b> Send emails directly from the application. Manage email templates and mailing lists.</li>
                        <li><b>Dynamic Graphs:</b> Visualize sales trends and inventory data.</li>
                        <li><b>Employee Management:</b> Manage employee records and attendance.</li>
                        <li><b>Expenditure Management:</b> Record and categorize expenses.</li>
                        <li><b>Account Management:</b> Track financial transactions and accounts.</li>
                      </ul>
                    </p>

                    <button style={{ float: "right" }} className="btn btn-primary text-white bg-gradient1 color-2 btnReadMore">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-4 border bg-light">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    class="card-img-top projectimg"
                    src={process.env.PUBLIC_URL + "/assets/projects/nft-marketplace-evm.png"}
                    alt="NFT Marketplace on Ethereum Chain portfolio"
                  />
                </div>
                <div className="col-lg-6">
                  <div class="card-body ">
                    <h2>NFT Marketplace</h2>
                    <p class="card-text mt-4">
                    NFT Marketplace is a web application built Ethereum blockchain. Built with Solidity for smart contracts and Web3, IPFS, and React.js. It provides all the essential functionalities to build a reliable & powerful marketplace, as well as a catchy and user-friendly design that is compatible with both desktops and mobile.
                      <br></br>
                      <b>Key Features:</b>
                      <ul>
                        <li>Auto detecting if the user is using MetaMask.</li>
                        <li>Auto connect the application with the user MetaMask wallet.</li>
                        <li>Detect the connected network as well as account changing.</li>
                        <li>Create & Sell and Buy NFts.</li>
                        <li>Calculate the user funds.</li>
                        <li>Give the user the ability to collect his funds.</li>
                        <li>Calculate the Top Sellers as well as their selling rank.</li>
                        <li>Sync the MetaMask avatar with the marketplace user avatar.</li>
                        <li>User can track all your assets on a single page.</li>
                        <li>Track your transactions on EtherScan.</li>
                        <li>Filter NFTs according to their category.</li>
                      </ul>
                    </p>

                    <button style={{ float: "right" }} className="btn btn-primary text-white bg-gradient1 color-2 btnReadMore">
                      <a style={{textDecoration: "none", color: "white"}} href="https://nft-marketplace-evm.vercel.app/" rel="noreferrer" target="_blank" >Read More</a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" d-flex btnProject">
          <button className="btn btn-primary text-white bg-gradient1 color-2">Explore More</button>
        </div>
      </div>
    </>
  );
}
