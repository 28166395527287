import './App.css';
import Home from './pages/home';
import Teams from './pages/Teams';
import { Routes, Route } from "react-router-dom";
import { Analytics } from '@vercel/analytics/react';
import Web from './pages/WebServices';
import TopNONEVM from './blogs/TopNONEVM';
import BlogList from './pages/BlogList';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const helmetContext = {};
  return (
    <>
   <HelmetProvider context={helmetContext}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/team" element={<Teams />} />
          <Route path="/web-development-services" element={<Web />} />
          <Route path="/blogs/top-non-evm-blockchain-plateforms" element={<TopNONEVM />} />
          <Route path="/blogs" element={<BlogList />} />
        </Routes>
        <Analytics />
      </HelmetProvider>
    </>
  );
}

export default App;
